import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private _http = inject(HttpClient);
  private _headers!: HttpHeaders;
  private _baseUrl = `${environment.apiUrl}/api`;

  constructor() {
    this._headers = new HttpHeaders().set('Content-Type', 'application/json');
    this._headers = this._headers.append('Accept', 'application/json');
    this._headers = this._headers.append('Access-Control-Allow-Origin', '*');
  }

  //#region API Calls

  /** Builds the http `GET` for md comp
   * @param T - the type of object to return
   * @param url - the url to call (e.g. 'controller/function') base url and 'api' are added in this service
   * @param httpParams - optional params to pass to the get request for filtering "?key=value"
   * @return `Observable<T>` - the object returned from the get request as the body
   */
  httpGet<T>(url: string, httpParams: Map<string, string> | undefined = undefined): Observable<T> {
    let params = new HttpParams();
    if (httpParams) {
      if (httpParams.size > 0) {
        httpParams.forEach((value: string, key: string) => {
          params = params.append(key, value);
        });
      }
    }
    const options = { headers: this._headers, params: params };
    return this._http.get<T>(`${this._baseUrl}/${url}`, options);
  }

  /** Builds the http `POST` for md comp
   * @param T - the type of object to return
   * @param url - the url to call (e.g. 'controller/function') base url and 'api' are added in this service
   * @param body - the object to pass to the post request as the body
   * @returns `Observable<T>` - the object returned from the post request as the body
   */
  httpPost<T>(url: string, body: T, httpParams: Map<string, any> | undefined = undefined): Observable<T> {
    let params = new HttpParams();
    if (httpParams) {
      if (httpParams.size > 0) {
        httpParams.forEach((value: any, key: string) => {
          params = params.append(key, value);
        });
      }
    }

    const options = { headers: this._headers, params: params };

    return this._http
      .post<T>(`${this._baseUrl}/${url}`, body, options)
      //.pipe(catchError(this.handleError));
  }

  httpPostGeneric<T, S>(url: string, body: T, httpParams: Map<string, any>): Observable<S>{
    let params = new HttpParams();
    if (httpParams) {
      if (httpParams.size > 0) {
        httpParams.forEach((value: any, key: string) => {
          params = params.append(key, value);
        });
      }
    }
    const options = { headers: this._headers, params: params };
    
    return this._http.post<S>(`${this._baseUrl}/${url}`, body, options);
  }

  httpPutGeneric<T, S>(url: string, body: T, httpParams: Map<string, any> | undefined = undefined): Observable<S> {
    let params = new HttpParams();
    if (httpParams) {
      if (httpParams.size > 0) {
        httpParams.forEach((value: any, key: string) => {
          params = params.append(key, value);
        });
      }
    }
    const options = { headers: this._headers, params: params };
    return this._http
      .put<S>(`${this._baseUrl}/${url}`, body, options);
      //.pipe(catchError(this.handleError));
  }

  httpPut<T>(url: string, body: T, httpParams: Map<string, any> | undefined = undefined): Observable<T> {
    let params = new HttpParams();
    if (httpParams) {
      if (httpParams.size > 0) {
        httpParams.forEach((value: any, key: string) => {
          params = params.append(key, value);
        });
      }
    }
    const options = { headers: this._headers, params: params };
    return this._http
      .put<T>(`${this._baseUrl}/${url}`, body, options);
      //.pipe(catchError(this.handleError));
  }

  httpDelete<T>(url: string): Observable<T> {
    return this._http
      .delete<T>(`${this._baseUrl}/${url}`, { headers: this._headers })
      //.pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error('A client-side or network error occurred:', error.error);
    } else {
      console.error(`Backend returned code ${error.status}, body was:`, error.error);
    }
    return throwError(() => new Error('Something went wrong; please try again later.'));
  }
}
